import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import { requests } from '../../../../../core/ajax'
import { feedback } from '../../../../../core/feedback'
import { downloadFile } from '../../../../../core/utils'
import { setApplication } from '../../../slices'
import { URL_CANCEL_LINK } from './constants'

export const useBudgetStep = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { getById, putById, put } = requests

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const downloadBudgetFile = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getById('/documento', id)
          .then(({ data }) => downloadFile(data['documento'], data['extension'], data['nombre']))
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    []
  )

  const goToNextStep = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        const payload = { aceptacion: true }
        putById('/presupuesto/aceptar', id, payload)
          .then(({ data }) => dispatch(setApplication(data)))
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    []
  )

  const cancelApplication = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/cambiar_estado/${id}/00`)
          .then(() => {
            window.location = `${URL_CANCEL_LINK}?solicitud=${id}`
          })
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    []
  )

  return {
    downloadBudgetFile,
    goToNextStep,
    cancelApplication,
  }
}
