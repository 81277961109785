import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core'

import { encodeToBase64, isACorrectFormat } from '../../../core/utils'
import { useAjaxContext } from '../../../core/ajax'
import { ThemeButton } from '../../../ui'
import { DEFAULT_FILE_SIZE, API_ENDPOINT } from '../constants'
import { fileUploaderStyles } from './file-uploader.styles'

export const FileUploader = withStyles(fileUploaderStyles)(
  ({
    classes,
    label,
    accept,
    onSuccess,
    onError,
    disabled,
    maxSize = DEFAULT_FILE_SIZE,
    type = 'certificados',
    applicationId,
    budgetId,
    ...props
  }) => {
    const { post } = useAjaxContext()
    const userId = useSelector((state) => state.global.authData.user_id)
    const [loading, setLoading] = useState(false)

    const handleChangeUpload = async (e) => {
      setLoading(true)
      const file = e.target.files[0]
      if (file) {
        if (accept && accept.length > 0 && !isACorrectFormat(file.name, accept)) {
          setLoading(false)
          return onError({ mssg: 'Archivo con formato no válido' })
        } else if (file.size > maxSize) {
          setLoading(false)
          return onError({ mssg: 'Excede el tamaño máximo' })
        }
        const mockErrorData = {
          mssg: 'Problema con la subida del fichero',
        }
        postDocument(file)
          .then(({ data }) => {
            setLoading(false)
            return onSuccess(data)
          })
          .catch(({ response }) => {
            setLoading(false)
            return onError(response ? response.data : mockErrorData)
          })
      }
    }

    const postDocument = async (file) => {
      const base64File = await encodeToBase64(file)
      let payload = {
        documento: base64File,
        nombre: file.name.replace(/\.[^/.]+$/, ''),
        tipo: type,
        extension: file.type.split('/')[1],
        cliente_id: userId,
        solicitud_id: applicationId.toString(),
      }
      if (budgetId) {
        payload = { ...payload, presupuesto_id: budgetId.toString() }
      }
      return post(API_ENDPOINT, payload)
    }

    return (
      <div>
        <input
          id={`file-upload-${applicationId}-${type}`}
          type="file"
          accept={accept.join(',')}
          className={classes.input}
          onChange={handleChangeUpload}
          disabled={disabled}
        />
        <label htmlFor={`file-upload-${applicationId}-${type}`}>
          <ThemeButton component="span" {...{ loading, disabled, ...props }}>
            {label}
          </ThemeButton>
        </label>
      </div>
    )
  }
)
