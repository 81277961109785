import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Hidden, Divider } from '@material-ui/core'

import { ExpansionPanel } from '../../../../../ui'
import { ReactComponent as NetworkIcon } from '../../../../../resources/images/icon_network.svg'
import { NetworkEventsList } from './network-events-list.component'
import { networkStepStyles } from './network-step.styles'

export const NetworkStepComponent = withStyles(networkStepStyles)(({ classes, model }) => {
  const { formatMessage } = useIntl()

  const licenseState =
    model['f_peticion_licencia'] && model['f_licencia'] ? 3 : model['f_peticion_licencia'] ? 2 : 1

  const constrState =
    model['f_inicio_ejec_infraestructuras'] && model['f_final_ejec_infraestructuras']
      ? 3
      : model['f_inicio_ejec_infraestructuras']
      ? 2
      : 1

  return (
    <ExpansionPanel
      isExpanded
      triggerPosition="right"
      summary={
        <>
          <Box display="flex" alignItems="center" flexGrow={2} mb={1}>
            <Hidden xsDown>
              <NetworkIcon />
            </Hidden>
            <Typography variant="h5">
              {formatMessage({ id: 'pages.gasApplications.wizard.network.title' })}
            </Typography>
          </Box>
          <Typography variant="body2" paragraph>
            {formatMessage({ id: 'pages.gasApplications.wizard.steps.3.tooltip' })}
          </Typography>
          <Box mb={2}>
            <Typography variant="body2" color="primary" className={classes.subtitle}>
              {formatMessage({ id: 'pages.gasApplications.wizard.network.subtitle1' })}
            </Typography>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box display="flex">
                <Typography variant="body2" color="textSecondary" className={classes.marginRight}>
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.network.fields.stateTitle',
                  })}
                  :
                </Typography>
                <Typography variant="body2">
                  {formatMessage({
                    id: `pages.gasApplications.wizard.network.fields.state.${licenseState}`,
                  })}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="body2" color="textSecondary" className={classes.marginRight}>
                  {formatMessage({ id: 'pages.gasApplications.wizard.network.fields.startDate' })}:
                </Typography>
                <Typography variant="body2">{model['f_peticion_licencia'] || '--'}</Typography>
              </Box>
              <Box display="flex">
                <Typography variant="body2" color="textSecondary" className={classes.marginRight}>
                  {formatMessage({ id: 'pages.gasApplications.wizard.network.fields.endDate' })}:
                </Typography>
                <Typography variant="body2">{model['f_licencia'] || '--'}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider light />
          <Box mt={2}>
            <Typography variant="body2" color="primary" className={classes.subtitle}>
              {formatMessage({ id: 'pages.gasApplications.wizard.network.subtitle2' })}
            </Typography>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box display="flex">
                <Typography variant="body2" color="textSecondary" className={classes.marginRight}>
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.network.fields.stateTitle',
                  })}
                  :
                </Typography>
                <Typography variant="body2">
                  {formatMessage({
                    id: `pages.gasApplications.wizard.network.fields.state.${constrState}`,
                  })}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="body2" color="textSecondary" className={classes.marginRight}>
                  {formatMessage({ id: 'pages.gasApplications.wizard.network.fields.startDate' })}:
                </Typography>
                <Typography variant="body2">
                  {model['f_inicio_ejec_infraestructuras'] || '--'}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="body2" color="textSecondary" className={classes.marginRight}>
                  {formatMessage({ id: 'pages.gasApplications.wizard.network.fields.endDate' })}:
                </Typography>
                <Typography variant="body2">
                  {model['f_final_ejec_infraestructuras'] || '--'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      }
      // details={<NetworkEventsList model={model} />}
    />
  )
})
