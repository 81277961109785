import React, { useContext } from 'react'
import { Dialog, withStyles, IconButton, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useWidth } from '../../../core/utils'
import { ModalContext } from '../contexts'
import { TYPES } from '../constants'
import { modalStyles } from './modal.styles'

export const ModalComponent = withStyles(modalStyles)(({ classes }) => {
  const {
    actions: { close },
    isOpen,
    type,
    Component,
    data,
    closable = true,
  } = useContext(ModalContext)

  const isMobileWidth = useWidth() === 'xs'

  return (
    <Dialog
      open={isOpen}
      fullScreen={type === TYPES.FULL_SCREEN || (type === TYPES.RESPONSIVE && isMobileWidth)}
      className={classes[type]}
      fullWidth
    >
      {closable ? (
        <Box m={6} onClick={close}>
          <IconButton aria-label="close" className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}
      <Component close={close} {...data} />
    </Dialog>
  )
})
