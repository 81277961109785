import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Hidden, Divider } from '@material-ui/core'

import { ExpansionPanel } from '../../../../../ui'
import { ReactComponent as ContractIcon } from '../../../../../resources/images/icon_contract.svg'
import { ContractEventsList } from './contract-events-list.component'
import { CONTRACT__COMPANIES_URL } from './constants'
import { contractStepStyles } from './contract-step.style'
import { CancelBtn } from '../../buttons'

export const ContractStepComponent = withStyles(contractStepStyles)(({ classes, model }) => {
  const { formatMessage } = useIntl()

  return (
    <ExpansionPanel
      isExpanded
      triggerPosition="right"
      summary={
        <Box>
          <Box mb={1} display="flex" alignItems="center" flexGrow={2}>
            <Hidden xsDown>
              <ContractIcon />
            </Hidden>
            <Typography variant="h5">
              {formatMessage({ id: 'pages.gasApplications.wizard.contract.title' })}
            </Typography>
          </Box>
          <Typography variant="body2" paragraph>
            {formatMessage({ id: 'pages.gasApplications.wizard.steps.4.tooltip' })}
          </Typography>
          <Box mb={2} display="flex" alignItems="center" flexWrap="wrap">
            <Typography variant="body2" className={classes.marginRight}>
              {formatMessage({ id: 'pages.gasApplications.wizard.contract.stateTitle' })}:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {model['comercializadora'] ||
                formatMessage({ id: 'pages.gasApplications.wizard.contract.state.1' })}
            </Typography>
          </Box>
          <Divider light />
          <Box mt={2} display="flex" alignItems="center">
            <Typography variant="body2" color="textSecondary" className={classes.marginRight}>
              {formatMessage({ id: 'pages.gasApplications.wizard.contract.description1' })}
              <span
                onClick={() => window.open(CONTRACT__COMPANIES_URL)}
                className={classes.urlLink}
              >
                {formatMessage({ id: 'pages.gasApplications.wizard.contract.description2' })}
              </span>
              {formatMessage({ id: 'pages.gasApplications.wizard.contract.description3' })}
            </Typography>
            <CancelBtn {...{ model }} />
          </Box>
        </Box>
      }
      // details={<ContractEventsList model={model} />}
    />
  )
})
