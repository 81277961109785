import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Backdrop, Box, CircularProgress, Button } from '@material-ui/core'
import { Document, Page, pdfjs } from 'react-pdf'

import { ModalContext } from '../../../../../ui/modal/contexts'
import { useAjaxContext } from '../../../../../core/ajax'
import { ViewPDFDialog } from '../../../../../ui/view-pdf-dialog'
import { feedback } from '../../../../../core/feedback'

// const BudgetViewDialog = ({ classes, close, file, setChecked }) => {
//   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
//   const { formatMessage } = useIntl()
//   const [numPages, setNumPages] = useState(null)
//   const [pageNumber, setPageNumber] = useState(1)

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages)
//   }

//   const handleNextPage = () => {
//     if (pageNumber < numPages) setPageNumber(pageNumber + 1)
//     else {
//       setChecked(true)
//       close()
//     }
//   }

//   const handlePrevPage = () => {
//     if (pageNumber > 1) setPageNumber(pageNumber - 1)
//     else {
//       close()
//     }
//   }

//   return (
//     <>
//       <Document
//         style={{ textAlign: 'center', position: 'absolute' }}
//         file={file}
//         onLoadSuccess={onDocumentLoadSuccess}
//         onLoadError={console.error}
//       >
//         <Page pageNumber={pageNumber} scale={1.8} />
//         <div className={classes.pdfButtonsWrapper}>
//           <Button
//             variant="contained"
//             color="primary"
//             className={classes.pdfButton}
//             onClick={handlePrevPage}
//           >
//             {pageNumber === 1
//               ? formatMessage({ id: 'global.close' })
//               : formatMessage({ id: 'global.previous' })}
//           </Button>
//           <Button
//             variant="contained"
//             color="primary"
//             className={classes.pdfButton}
//             onClick={handleNextPage}
//           >
//             {pageNumber === numPages
//               ? formatMessage({ id: 'global.accept' })
//               : formatMessage({ id: 'global.next' })}
//           </Button>
//         </div>
//       </Document>
//     </>
//   )
// }

export const BudgetMandatoryMssgComponent = ({ classes, fileId, setChecked, checked }) => {
  const { formatMessage } = useIntl()
  const { getById } = useAjaxContext()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const [isLoading, setLoading] = useState(false)

  const handleSetChecked = (bool) => setChecked(!bool)

  const handleShowFile = () => {
    setLoading(true)
    getById('/documento', fileId)
      .then(({ data }) => {
        const base64Data = data['documento'].split("'")[1]
        const file = `data:application/pdf;base64,${base64Data}`
        setLoading(false)
        open({
          Component: ViewPDFDialog,
          data: { file, classes, setDisabled: handleSetChecked },
          type: 'fullScreen',
          closable: false,
        })
      })
      .catch(() => {
        setLoading(false)
        feedback('error', 'No se encuentra el documento')
      })
  }

  return (
    <Box display="flex" flexWrap="wrap">
      <Typography variant="body2">
        {formatMessage({ id: 'pages.gasApplications.wizard.budget.mandatoryMssg1' })}
      </Typography>
      <Typography className={classes.documentLink} variant="body2" onClick={handleShowFile}>
        {formatMessage({ id: 'pages.gasApplications.wizard.budget.documentMssg' })}
      </Typography>
      <Typography variant="body2">
        {formatMessage({ id: 'pages.gasApplications.wizard.budget.mandatoryMssg2' })}
      </Typography>
      <Backdrop style={{ zIndex: 999 }} open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Box>
  )
}
