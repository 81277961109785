import { instances } from '../instances'

const { publicInstance, privateInstance } = instances

export const requests = {
  serverPing: () => publicInstance.get('check_working_api'),
  signupConfirm: (id) => publicInstance.get(`usuario/register_confirmation/${id}`),
  login: (data) => publicInstance.post('login/login_oauth2', { ...data, area: 'cliente' }),
  signup: (data) => publicInstance.post('/usuario/registrar_cliente', data),
  fetchBudget: (budgetId, userId) =>
    publicInstance.get(`/solicitudes/pre_area/${budgetId}/${userId}`),
  refreshApplication: (budgetId, data) => publicInstance.put(`/solicitudes/${budgetId}/`, data),
  changeApplicationPublicState: (appId, state) =>
    publicInstance.put(`/solicitudes/cambiar_estado_pre_area/${appId}/${state}`),
  changeApplicationState: (appId, state) =>
    privateInstance.put(`/solicitudes/cambiar_estado/${appId}/${state}`),
  getById: (url, id, config) => privateInstance.get(`${url}/${id}`, config),
  get: (url, config) => privateInstance.get(url, config),
  post: (url, data, config) => privateInstance.post(url, data, config),
  postPublic: (url, data) => publicInstance.post(url, data),
  put: (url, data, config) => privateInstance.put(url, data, config),
  putById: (url, id, data, config) => privateInstance.put(`${url}/${id}`, data, config),
  delete: (url, id, config) => privateInstance.delete(`${url}/${id}`, config),
}
