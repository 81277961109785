const { REACT_APP_API_URL } = process.env

export const PUBLIC_INSTANCE_CONFIG = {
  baseURL: REACT_APP_API_URL,
  timeout: 10000,
}

export const PRIVATE_INSTANCE_CONFIG = {
  baseURL: REACT_APP_API_URL,
  timeout: 10000,
}
