import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { Box, CircularProgress } from '@material-ui/core'

import { PrivateLayoutComponent } from '../../ui'
import { Home, Profile, GasApplications, Contact, Settings, PaymentResult } from '../../pages'
import { NotificationsContext } from '../../features/notifications/contexts'
import { useGasAppData } from '../../features/gas-applications'
import { useAjaxContext } from '../../core/ajax'
import { setUserData } from '../../features/profile/slices'
import { useGlobalActions } from '../../features/global'

const BarePrivateRouter = ({ history, location }) => {
  const dispatch = useDispatch()
  const { get } = useAjaxContext()
  const userId = useSelector((state) => state.global.authData.user_id)
  const { applications } = useGasAppData()
  const { logoutUser } = useGlobalActions()

  const [loadingUser, setLoadingUser] = useState(true)

  const { loadNotifications, showNotification } = useContext(NotificationsContext)
  const [notify, setNotify] = useState(null)
  const notifLoader = async (user_id, location) => {
    await loadNotifications(user_id)
    const params = new URLSearchParams(location.search)
    const tryNotify = params.get('notify')
    if (tryNotify) {
      setNotify(parseInt(tryNotify))
    }
  }
  const notifShower = async (notify) => {
    if (notify) {
      try {
        await showNotification(notify)
      } catch (err) {
        console.log('The requested notification was not found')
      }
      setNotify(null)
      const currentParams = new URLSearchParams(location.search)
      currentParams.delete('notify')
      history.replace({
        search: '?' + currentParams.toString(),
      })
    }
  }
  useEffect(() => {
    notifLoader(userId, location)
  }, [location, userId])

  useEffect(() => {
    notifShower(notify)
  }, [notify])

  useEffect(() => {
    setLoadingUser(true)
    get('/usuario/' + userId)
      .then(({ data }) => {
        dispatch(setUserData(data))
        setLoadingUser(false)
        if (!data['nombre']) history.push('/profile')
      })
      .catch(({ response }) => {
        if (response.status === 403) {
          logoutUser()
          //setTimeout(window.location.reload(), 1000)
        }
        return setLoadingUser(false)
      })
  }, [])

  return (
    <PrivateLayoutComponent>
      {loadingUser || !applications ? (
        <Box
          display="flex"
          height="100%"
          width={'100%'}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={40} />
        </Box>
      ) : (
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/profile" component={Profile} />
          <Route exact path="/gas-applications/payment/:appId" component={PaymentResult} />
          <Route path="/gas-applications" component={GasApplications} />
          <Route path="/contact" component={Contact} />
          <Route path="/settings" component={Settings} />
          <Redirect from="*" to={applications.length > 0 ? '/gas-applications' : '/home'} />
        </Switch>
      )}
    </PrivateLayoutComponent>
  )
}

export const PrivateRouter = withRouter(BarePrivateRouter)
