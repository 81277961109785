import { APPLICATION_STATES } from '../../../constants'

export const PAYMENT_STEP_COMPLETED_STATES = {
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_REJECTED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
  [APPLICATION_STATES.FINISHED.KEY]: true,
}

export const PAYMENT_NEEDED_STATES = {
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
}

export const PAYMENT_FILE_SIZE = 10000000
