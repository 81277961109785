import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'
import { FILTERED_STATES, PROCESSING_STATES } from '../constants'

export const useGasAppCalls = () => {
  const { formatMessage } = useIntl()
  const { getById, post, putById, put } = requests

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getGasApps = useCallback(
    (userId) =>
      new Promise((resolve, reject) => {
        getById('/solicitudes/by_usuario', userId)
          .then(({ data }) =>
            // GAS_APPS_ITEMS
            data
              .filter(
                (application) =>
                  // !FILTERED_STATES[application['estado']] &&
                  //Workaround to prevent applications without camino_solicitud or with incoherences
                  application['camino_solicitud'] &&
                  ((application['camino_solicitud'] === '01' &&
                    PROCESSING_STATES[application.estado]) ||
                    (application['camino_solicitud'] === '01' &&
                      !PROCESSING_STATES[application.estado]) ||
                    application['camino_solicitud'] !== '01')
              )
              .sort((a, b) => new Date(b['fecha_solicitud']) - new Date(a['fecha_solicitud']))
          )
          .then((cleanData) => resolve(cleanData))
          .catch((error) => {
            return reject(error.response)
          })
      }),
    []
  )

  const getGasApp = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getById('/solicitudes', id)
          .then(({ data }) =>
            // GAS_APPS_ITEMS
            resolve(data)
          )
          .catch((error) => {
            return reject(error.response)
          })
      }),
    []
  )

  const surveyPost = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        post('/encuesta', { solicitud_cidi_serial_id: id })
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    []
  )

  const cancelPut = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        putById('/solicitudes/anular_solicitud', id, payload)
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    []
  )

  const rollbackPut = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        putById('/solicitudes', id, payload)
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    []
  )

  const uploadDocument = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        post('/documento', payload)
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    []
  )

  const reviewCall = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/cambiar_estado/${id}/00`, payload)
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    []
  )

  return {
    getGasApps,
    rollbackPut,
    getGasApp,
    surveyPost,
    cancelPut,
    uploadDocument,
    reviewCall,
  }
}
