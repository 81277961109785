import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, TextInput, SelectInput } from '../../../../../ui'
import { CancelAppDialog } from '../cancel-app-dialog'
import { useGasAppData } from '../../../hooks'
import { useModalContext } from '../../../../../ui'
import { feedback } from '../../../../../core/feedback'

export const PendingAppDialog = ({ close, cancelPendingApplications }) => {
  const { formatMessage, formatNumber } = useIntl()
  const { rollbackApp, cancelApplication } = useGasAppData()
  const {
    actions: { open },
  } = useModalContext()
  const [rollbackLoading, setRollbackLoading] = useState(false)

  const handleOpenModal = (app) =>
    open({
      Component: CancelAppDialog,
      data: { application: app, cancelApplication },
      type: 'centered',
    })

  const handleRollback = (app) => {
    setRollbackLoading(true)
    const payload = { estado: app['estado_anterior'] }
    rollbackApp(app, payload)
      .then(() => {
        feedback(
          'success',
          formatMessage({ id: 'pages.gasApplications.dialogs.pendingApps.Reject' })
        )
        close()
        setRollbackLoading(false)
      })
      .catch((err) => {
        setRollbackLoading(false)
      })
  }

  return (
    <Box p={4} display="flex" justifyContent="flex-start" flexDirection="column">
      <Box display="flex">
        <Typography variant="h6" color="primary" paragraph>
          {formatMessage(
            { id: 'pages.gasApplications.dialogs.pendingApps.title' },
            {
              amount: formatNumber(cancelPendingApplications.length),
            }
          )}
        </Typography>
      </Box>
      {cancelPendingApplications?.length > 0
        ? cancelPendingApplications.map((app) => {
            let address =
              `${app['tipo_calle'] ? app['tipo_calle'] : ''} ${
                app['direccion'] ? app['direccion'] : ''
              } ${app['numero'] ? app['numero'] : ''}` +
              `${app['bloque'] ? '-' + app['bloque'] : ''} ` +
              `${app['escalera'] ? '-' + app['escalera'] : ''} ` +
              `${app['piso'] ? app['piso'] : ''} ${app['puerta'] ? app['puerta'] : ''},` +
              '\n' +
              `${app['cod_postal'] ? app['cod_postal'] : ''} ${
                app['municipio'] ? app['municipio'] : ''
              }  ${app['provincia'] ? `(${app['provincia']})` : ''}`
            return (
              <Box display="flex">
                <Box display="flex" alignItems="center" width="35%">
                  <Typography paragraph p={4} variant="h8" color="primary">
                    {address}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end" width="65%">
                  <Box mr={2}>
                    <ThemeButton
                      labelM="medium"
                      color="default"
                      onClick={() => handleRollback(app)}
                      disabled={rollbackLoading}
                      loading={rollbackLoading}
                    >
                      {formatMessage({ id: 'global.mantener' })}
                    </ThemeButton>
                  </Box>
                  <Box>
                    <ThemeButton
                      labelM="medium"
                      color="secondary"
                      onClick={() => handleOpenModal(app)}
                    >
                      {formatMessage({ id: 'global.annul' })}
                    </ThemeButton>
                  </Box>
                </Box>
              </Box>
            )
          })
        : null}
      <Box display="flex" justifyContent="flex-end">
        <ThemeButton labelM="large" color="primary" onClick={close}>
          {formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
