import { APPLICATION_STATES } from '../../../constants'

export const BUDGET_GRID_ROW_ITEMS = [
  {
    label: 'pages.budget.view.panel1.detail.grid.item2',
    valueParsed: (model, intl) =>
      intl.formatNumber(model['presupuesto_exterior']['distancia_real'], {
        style: 'unit',
        unit: 'meter',
      }),
  },
  {
    label: 'pages.budget.view.panel1.detail.grid.item3',
    valueParsed: (model, intl) =>
      intl.formatNumber(model['presupuesto_exterior']['ampliacion_asumida_por_cliente'], {
        style: 'unit',
        unit: 'meter',
      }),
  },
]

export const URL_CANCEL_LINK = `${process.env.REACT_APP_WP_URL}/no-me-interesa`

export const BUDGET_STEP_UNCANCELLABLE_STATES = {
  [APPLICATION_STATES.FINISHED.KEY]: APPLICATION_STATES.FINISHED.DESCRIPTION,
  [APPLICATION_STATES.REJECTED_SURVEY.KEY]: APPLICATION_STATES.REJECTED_SURVEY.DESCRIPTION,
  [APPLICATION_STATES.REJECTED.KEY]: APPLICATION_STATES.REJECTED.DESCRIPTION,
  [APPLICATION_STATES.REJECTED_2.KEY]: APPLICATION_STATES.REJECTED_2.DESCRIPTION,
  [APPLICATION_STATES.VALIDATION_PENDING]: APPLICATION_STATES.VALIDATION_PENDING,
  [APPLICATION_STATES.REJECTED_NORMALIZATION]: true,
}

export const MUST_ANSWER_CANCEL_REQUEST = {
  [APPLICATION_STATES.CLIENT_CANCEL_PENDING.KEY]: true,
}
