import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { withStyles, Container, Typography, Box } from '@material-ui/core'

import { ThemeButton } from '../../../ui'
import { GasAppDashboard } from '../../../features/gas-applications'
import { useGasAppData } from '../../../features/gas-applications'
import { gasApplicationsdStyles } from './gas-applications.styles'

export const GasApplicationsPage = withStyles(gasApplicationsdStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { applications, updateGasApps, cancelPendingApplications } = useGasAppData()

  useEffect(() => updateGasApps(), [])

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        {applications?.length !== 0 ? (
          <>
            <div className={classes.titleWrapper}>
              <Typography variant="h4" color="primary" paragraph>
                {formatMessage({ id: 'pages.gasApplications.title' })}
              </Typography>
            </div>
            <div className={classes.contentWrapper}>
              <GasAppDashboard />
            </div>
          </>
        ) : (
          <Box textAlign="center">
            <Typography variant="h5" paragraph>
              {formatMessage({ id: 'pages.gasApplications.notFound' })}
            </Typography>
            <Link to="/home" replace>
              <ThemeButton type="submit" color="primary" labelM="large">
                {formatMessage({ id: 'global.return' })}
              </ThemeButton>
            </Link>
          </Box>
        )}
      </Container>
    </div>
  )
})
