import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../../ui'
import { AttachmentInput } from './attachment-input.component'

export const RequestChangesDialog = ({ close, id, requestReview }) => {
  const { formatMessage } = useIntl()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    const payload = { comentario: formState.comentario }
    requestReview(id, payload, formState.file)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  console.log('form', formState)
  return (
    <Box p={4}>
      <Box mb={4}>
        <Typography variant="h4" color="primary" align="center">
          {formatMessage({ id: 'pages.gasApplications.dialogs.requestChanges.title' })}
        </Typography>
      </Box>
      <Typography variant="body1" color="textSecondary" align="left" paragraph>
        {formatMessage({ id: 'pages.gasApplications.dialogs.requestChanges.description' })}
      </Typography>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <TextInput
            required
            name="comentario"
            title={`${formatMessage({
              id: 'pages.gasApplications.dialogs.requestChanges.fields.1.title',
            })}:`}
            value={formState['comentario']}
            multiline
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <AttachmentInput
            {...{ name: 'file', value: formState['file'], onChange: handleChangeForm }}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="large" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="large"
            disabled={loading}
            loading={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
