import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Typography, Divider, Hidden } from '@material-ui/core'

import { ExpansionPanel } from '../../../../ui'
import { ReactComponent as CommissioningIcon } from '../../../../resources/images/icon_commissioning.svg'
import { budgetRequestPanel2Styles } from './budget-request-panel-2.styles'
import { URL_NEDGIA_LINK } from '../../constants'

export const BudgetRequestPanel2Component = withStyles(budgetRequestPanel2Styles)(
  ({ classes, data }) => {
    const { formatMessage, formatNumber } = useIntl()

    let fixCost =
      data['presupuesto_exterior']['derechos_acometida'] +
      data['presupuesto_exterior']['derechos_alta']
    let fixCostIva = Math.floor(data['presupuesto_exterior']['iva'] * fixCost) / 100
    let fixCostTotal = fixCost + fixCostIva

    return (
      <ExpansionPanel
        inactive={true}
        summary={
          <>
            <div className={classes.section_1}>
              <div className={classes.section_1_1}>
                <Hidden xsDown className={classes.section_1_1_1}>
                  <CommissioningIcon />
                </Hidden>
                <div className={classes.section_1_1_2}>
                  <Typography variant="body2">
                    {formatMessage({ id: 'pages.budget.view.panel2.summary.left.title' })}
                  </Typography>
                  <Typography variant="h5">
                    {formatMessage({ id: 'pages.budget.view.panel2.summary.left.subtitle' })}
                  </Typography>
                </div>
              </div>
              {/* <div className={classes.section_1_2}>
              <Typography variant="body2">
                {formatMessage({ id: 'pages.budget.view.panel2.summary.right.title' })}
              </Typography>
              <Typography variant="h5" color="secondary">
                {formatNumber(fixCostTotal, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </Typography>
            </div> */}
            </div>
            <div className={classes.section_2}>
              <Divider light style={{ marginTop: '1em', marginBottom: '1em' }} />
              <Typography>
                {formatMessage({ id: 'pages.budget.view.panel2.message.preLink' })}
                <a href={URL_NEDGIA_LINK}> www.nedgia.es </a>
                {formatMessage({ id: 'pages.budget.view.panel2.message.postLink' })}
              </Typography>
            </div>
          </>
        }
        details={null}
        // details={
        //   <div className={classes.section_2}>
        //     <Divider light />
        //     <div className={classes.section_2_2}>
        //       <div className={classes.section_2_2_1}>
        //         <div className={classes.section_2_2_1_1}>
        //           <Typography variant="subtitle2">
        //             {formatMessage({ id: 'pages.budget.view.panel2.detail.row1.col1' })}
        //           </Typography>
        //         </div>
        //         <div className={classes.section_2_2_1_2}>
        //           <div className={classes.section_2_2_1_2_1}>
        //             <Typography variant="body2" color="textSecondary">
        //               {formatMessage({ id: 'pages.budget.view.panel2.detail.row1.col2' })}
        //             </Typography>
        //           </div>
        //           <div className={classes.section_2_2_1_2_2}>
        //             <Typography variant="body2" color="textSecondary">
        //               {formatNumber(data['presupuesto_exterior']['derechos_acometida'], {
        //                 style: 'currency',
        //                 currency: 'EUR',
        //               })}
        //             </Typography>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //     <div className={classes.section_2_3}>
        //       <div className={classes.section_2_3_1}>
        //         <div className={classes.section_2_3_1_1}>
        //           <Typography variant="subtitle2">
        //             {formatMessage({ id: 'pages.budget.view.panel2.detail.row2.col1' })}
        //           </Typography>
        //         </div>
        //         <div className={classes.section_2_3_1_2}>
        //           <div className={classes.section_2_3_1_2_1}>
        //             <Typography variant="body2" color="textSecondary">
        //               {formatMessage({ id: 'pages.budget.view.panel2.detail.row2.col2' })}
        //             </Typography>
        //           </div>
        //           <div className={classes.section_2_3_1_2_2}>
        //             <Typography variant="body2" color="textSecondary">
        //               {formatNumber(data['presupuesto_exterior']['derechos_alta'], {
        //                 style: 'currency',
        //                 currency: 'EUR',
        //               })}
        //             </Typography>
        //           </div>
        //         </div>
        //       </div>
        //       <div className={classes.section_2_3_2}>
        //         <div className={classes.section_2_3_2_1}>
        //           <Typography variant="body2" color="textSecondary" display="inline">
        //             {formatMessage({ id: 'pages.budget.view.panel2.detail.row2b.col1' })}
        //           </Typography>
        //         </div>
        //         <div className={classes.section_2_3_2_2}>
        //           <Typography variant="body2" color="textSecondary">
        //             {formatNumber(fixCost, {
        //               style: 'currency',
        //               currency: 'EUR',
        //             })}
        //           </Typography>
        //         </div>
        //       </div>
        //       <div className={classes.section_2_3_2}>
        //         <div className={classes.section_2_3_2_1}>
        //           <Typography variant="body2" color="textSecondary" display="inline">
        //             {`${formatMessage({ id: 'pages.budget.view.panel2.detail.row3.col1' })}
        //             ${data['presupuesto_exterior']['iva']}%`}
        //           </Typography>
        //         </div>
        //         <div className={classes.section_2_3_2_2}>
        //           <Typography variant="body2" color="textSecondary">
        //             {formatNumber(fixCostIva, {
        //               style: 'currency',
        //               currency: 'EUR',
        //             })}
        //           </Typography>
        //         </div>
        //       </div>
        //       <div className={classes.section_2_3_3}>
        //         <div className={classes.section_2_3_3_1}>
        //           <Typography variant="subtitle2">
        //             {formatMessage({ id: 'pages.budget.view.panel2.detail.row4.col1' })}
        //           </Typography>
        //         </div>
        //         <div className={classes.section_2_3_3_2}>
        //           <Typography variant="subtitle2">
        //             {formatNumber(fixCostTotal, {
        //               style: 'currency',
        //               currency: 'EUR',
        //             })}
        //           </Typography>
        //         </div>
        //       </div>
        //     </div>
        //     <Divider light />
        //     <div className={classes.section_2_4}>
        //       <div className={classes.section_2_4_1}>
        //         <div className={classes.section_2_4_1_1}>
        //           <Typography variant="subtitle2">
        //             {formatMessage({ id: 'pages.budget.view.panel2.detail.row5.col1' })}
        //           </Typography>
        //         </div>
        //         <div className={classes.section_2_4_1_2}>
        //           <Typography variant="body2" color="textSecondary">
        //             {formatMessage(
        //               { id: 'pages.budget.view.panel2.detail.row5.col2' },
        //               {
        //                 value: formatNumber(data['presupuesto_exterior']['alquiler_contador'], {
        //                   style: 'currency',
        //                   currency: 'EUR',
        //                 }),
        //               }
        //             )}
        //           </Typography>
        //         </div>
        //       </div>
        //       <div className={classes.section_2_4_2}>
        //         <div className={classes.section_2_4_2_1}>
        //           <Typography variant="subtitle2">
        //             {formatMessage({ id: 'pages.budget.view.panel2.detail.row6.col1' })}
        //           </Typography>
        //         </div>
        //         <div className={classes.section_2_4_2_2}>
        //           <Typography variant="subtitle2">
        //             {formatNumber(data['presupuesto_exterior']['alquiler_contador'], {
        //               style: 'currency',
        //               currency: 'EUR',
        //             })}
        //           </Typography>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // }
      />
    )
  }
)
