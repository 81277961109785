import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setComboById } from '../../combos/slices'

export const useCombos = (combos_ids, order_ids = []) => {
  const dispatch = useDispatch()
  const combos = useSelector((state) => state.combos)

  useEffect(() => {
    combos_ids.forEach((comboId) => {
      const order = order_ids.indexOf(comboId) > -1
      if (!combos[comboId]) dispatch(setComboById(comboId, order))
    })
  }, [combos_ids])

  return combos
}
