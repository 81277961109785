import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, TextInput, SelectInput } from '../../../../../ui'
import { useCombos } from '../../../../combos/hooks'

export const CancelRequestDialog = ({ close, application, cancelApplication, rollbackApp }) => {
  console.log(cancelApplication, rollbackApp)
  const { formatMessage } = useIntl()
  const combos = useCombos(['motivos_anulacion_cliente'])

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [rollbackLoading, setRollbackLoading] = useState(false)

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmitForm = (e) => {
    e.preventDefault()
    let reasons = {}
    combos.survey_options.data.forEach((reason) => (reasons[reason.key] = reason.value))
    const payload = {
      motivo_anulacion_cliente: formState.respuesta || '',
    }
    setLoading(true)
    cancelApplication(application, payload)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const handleRollback = () => {
    setRollbackLoading(true)
    const payload = { estado: application['estado_anterior'] }
    rollbackApp(application, payload)
      .then(() => {
        setRollbackLoading(false)
        close()
      })
      .catch((err) => {
        setRollbackLoading(false)
      })
  }

  return (
    <Box p={4}>
      <Typography variant="h4" color="primary" align="center" paragraph>
        {formatMessage({ id: 'pages.gasApplications.dialogs.cancelApp.title' })}
      </Typography>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <SelectInput
            required
            fullWidth
            name="respuesta"
            title={`${formatMessage({
              id: 'pages.gasApplications.dialogs.cancelApp.fields.1.title',
            })}:`}
            value={formState['respuesta']}
            values={combos?.motivos_anulacion_cliente?.data || []}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="space-around">
          <Box mr={2}>
            <ThemeButton color="default" labelM="medium" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <Box mr={2}>
            <ThemeButton
              color="secondary"
              labelM="medium"
              onClick={handleRollback}
              disabled={rollbackLoading}
            >
              {formatMessage({ id: 'global.rechazar' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="medium"
            disabled={loading}
            loading={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
