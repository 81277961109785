import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { setUserData } from '../../../../features/profile/slices'
import { TextInput, ThemeButton } from '../../../../ui'
import { useAjaxContext } from '../../../../core/ajax'
import { feedback } from '../../../../core/feedback'

export const EditProfileFormComponent = ({ data, close }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { putById } = useAjaxContext()
  const userId = useSelector((state) => state.global.authData.user_id)

  const [formState, setFormState] = useState(data)
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setLoading(true)
    putById('/usuario', userId, formState)
      .then(() => {
        setLoading(false)
        dispatch(setUserData(formState))
        close()
      })
      .catch(({ response }) => {
        setLoading(false)
        feedback(
          'error',
          (response.data && response.data.message) || 'No se han podido guardar los cambios'
        )
      })
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <TextInput
        required
        value={formState['nombre']}
        name="nombre"
        title={formatMessage({ id: 'pages.profile.edit.fields.name.title' })}
        placeholder={formatMessage({ id: 'pages.profile.edit.fields.name.placeholder' })}
        onChange={handleChangeForm}
      />
      <TextInput
        required
        value={formState['apellidos']}
        name="apellidos"
        title={formatMessage({ id: 'pages.profile.edit.fields.surname.title' })}
        placeholder={formatMessage({ id: 'pages.profile.edit.fields.surname.placeholder' })}
        onChange={handleChangeForm}
      />
      {/* <TextInput
        value={formState['identificador']}
        name="identificador"
        title="DNI (opcional)"
        placeholder="Introducir DNI"
        onChange={handleChangeForm}
      /> */}
      <TextInput
        type="email"
        required
        value={formState['email']}
        name="email"
        title={formatMessage({ id: 'pages.profile.edit.fields.email.title' })}
        placeholder={formatMessage({ id: 'pages.profile.edit.fields.email.placeholder' })}
        onChange={handleChangeForm}
      />
      <TextInput
        // required
        value={formState['telefono']}
        name="telefono"
        title={formatMessage({ id: 'pages.profile.edit.fields.phone.title' })}
        placeholder={formatMessage({ id: 'pages.profile.edit.fields.phone.placeholder' })}
        onChange={handleChangeForm}
      />
      {/* <TextInput
        disabled={true}
        value={formState['idioma']}
        name="idioma"
        title={formatMessage({ id: 'pages.profile.edit.fields.language.title' })}
        placeholder={formatMessage({ id: 'pages.profile.edit.fields.language.placeholder' })}
        onChange={handleChangeForm}
      /> */}
      <Box display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column" width={220}>
          <ThemeButton type="submit" fullWidth loading={loading}>
            Guardar cambios
          </ThemeButton>
          {data['nombre'] ? (
            <ThemeButton color="default" fullWidth onClick={close}>
              Cancelar
            </ThemeButton>
          ) : null}
        </Box>
      </Box>
    </form>
  )
}
