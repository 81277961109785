import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useLoginCalls } from '../../login/hooks/login-calls.hook'
import { setAuthData } from '../../global'
import { saveLocalStorage } from '../../../core/utils'

export const useGlobalActions = () => {
  const { loginCall } = useLoginCalls()
  const dispatch = useDispatch()

  const loginUser = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        loginCall(payload)
          .then(({ data }) => {
            saveLocalStorage('dsc', { 'apc-auth': data })
            dispatch(setAuthData({ ...data, isAuthorized: true }))
          })
          .then((response) => resolve(response))
          .catch((response) => {
            return reject(response)
          })
      }),
    []
  )

  const logoutUser = useCallback(() => {
    saveLocalStorage('dsc', { 'apc-auth': {} })
    dispatch(setAuthData({ isAuthorized: false }))
  }, [])

  return {
    loginUser,
    logoutUser,
  }
}
