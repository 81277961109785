import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Typography, Box, CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined'

import { ExpansionPanel } from '../../../../ui'
import { gasAppPanelStyles } from './gas-app-panel.styles'
import { GasAppWizard } from '../gas-app-wizard'
import { APPLICATION_STATES } from '../../constants/application-states.constants'
import {
  NORMALIZATION_STATES,
  PROCESSING_STATES,
  CANCELLING_STATES,
  CLIENT_PENDING_CANCEL,
} from './gas-app-panel.constants'
import { useGasAppCalls } from '../../hooks'
import { setApplication } from '../../slices'
import { CancelBtn } from '../buttons'

export const GasAppPanelComponent = withStyles(gasAppPanelStyles)(
  ({ classes, model, isExpanded = false }) => {
    const { formatMessage, formatDate } = useIntl()
    const { getGasApp } = useGasAppCalls()
    const dispatch = useDispatch()

    const address =
      `${model['tipo_calle'] ? model['tipo_calle'] : ''} ${
        model['direccion'] ? model['direccion'] : ''
      } ${model['numero'] ? model['numero'] : ''}` +
      `${model['bloque'] ? '-' + model['bloque'] : ''} ` +
      `${model['escalera'] ? '-' + model['escalera'] : ''} ` +
      `${model['piso'] ? model['piso'] : ''} ${model['puerta'] ? model['puerta'] : ''},` +
      '\n' +
      `${model['cod_postal'] ? model['cod_postal'] : ''} ${
        model['municipio'] ? model['municipio'] : ''
      }  ${model['provincia'] ? `(${model['provincia']})` : ''}`

    const handleExpand = (opening) => {
      if (opening && !model['individual_update']) {
        getGasApp(model['id']).then((data) => {
          dispatch(setApplication(data))
        })
      }
    }

    useEffect(() => {
      if (isExpanded) {
        handleExpand(true)
      }
    }, [])

    const panelDetails =
      PROCESSING_STATES[model['estado']] || NORMALIZATION_STATES[model['estado']] ? (
        <Box
          display="flex"
          justifyContent={NORMALIZATION_STATES[model['estado']] ? 'center' : 'space-between'}
          width="100%"
          pt={2}
          pb={1}
        >
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.gasApplications.panel.description.1' })}
          </Typography>
          {PROCESSING_STATES[model['estado']] ? (
            <Box ml={1}>
              <CancelBtn {...{ model }} />
            </Box>
          ) : null}
        </Box>
      ) : CANCELLING_STATES[model['estado']] ? (
        <Box display="flex" justifyContent="center" width="100%" pt={2} pb={1}>
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.gasApplications.panel.description.cancelled' })}
          </Typography>
        </Box>
      ) : CANCELLING_STATES[model['estado']] && PROCESSING_STATES[model['estado_anterior']] ? (
        <Box display="flex" justifyContent="center" width="100%" pt={2} pb={1}>
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.gasApplications.panel.description.cancelled' })}
          </Typography>
        </Box>
      ) : CLIENT_PENDING_CANCEL[model['estado']] ? (
        <Box display="flex" justifyContent="center" width="100%" pt={2} pb={1}>
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.gasApplication.panel.description_cancel' })}
          </Typography>
        </Box>
      ) : !model['individual_update'] ? (
        <Box display="flex" width="100%" justifyContent="center" alignItems="center">
          <CircularProgress size={40} />
        </Box>
      ) : (
        <GasAppWizard model={model} />
      )

    return (
      <div className={classes.wrapper}>
        <ExpansionPanel
          isExpanded={isExpanded}
          triggerPosition="right"
          onExpand={handleExpand}
          summary={
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box alignItems="center">
                {process.env.REACT_APP_ENVIRONMENT === 'Local' &&
                  `Id: ${model['id']} | Estado: ${model['estado']}`}
                {model['estado'] === APPLICATION_STATES.CLIENT_CANCEL_PENDING.KEY && (
                  <ReportOutlinedIcon />
                )}
                <Typography variant="h5" color="primary">
                  {formatMessage({ id: 'pages.gasApplications.panel.title' })}:
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {address}
                </Typography>
              </Box>
              <Box>
                <Box display="flex">
                  <Box mr={1}>
                    <Typography variant="subtitle2" display="inline">
                      {formatMessage({ id: 'pages.gasApplications.panel.cups' })}:
                    </Typography>
                  </Box>
                  <Typography variant="body2" display="inline" color="textSecondary">
                    {model['cups'] || '--'}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Box mr={1}>
                    <Typography variant="subtitle2" display="inline">
                      {formatMessage({ id: 'pages.gasApplications.panel.date' })}:
                    </Typography>
                  </Box>
                  <Typography variant="body2" display="inline" color="textSecondary">
                    {model['fecha_puesta_servicio']
                      ? formatDate(new Date(model['fecha_puesta_servicio']))
                      : '--'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          }
          summaryExtra={
            <Box display="flex">
              <Box mr={1}>
                <Typography variant="subtitle2" display="inline">
                  {formatMessage({ id: 'pages.gasApplications.panel.reference' })}:
                </Typography>
              </Box>
              <Typography variant="body2" display="inline" color="textSecondary">
                {model['solicitud_id']}
              </Typography>
            </Box>
          }
          details={panelDetails}
        />
      </div>
    )
  }
)
