import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Divider, Hidden, Button } from '@material-ui/core'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'

import { feedback } from '../../../../../core/feedback'
import { FileUploader } from '../../../../../ui'
import { ReactComponent as PaymentIcon } from '../../../../../resources/images/icon_payment.svg'
import TransferIcon from '../../../../../resources/icons/TransferIcon'
import CreditCardIcon from '../../../../../resources/icons/CreditCardIcon'
import { APPLICATION_STATES } from '../../../constants'
import { PAYMENT_STEP_COMPLETED_STATES, PAYMENT_FILE_SIZE } from './constants'
import { PAYMENT_NEEDED_STATES } from './constants'
import { usePaymentStep } from './payment-step.hook'
import { PaymentProcessor } from './payment-processor.component'
import { paymentStepStyles, optionButtonStyles } from './payment-step.styles'
import { CancelBtn } from '../../buttons'

const OptionButton = withStyles(optionButtonStyles)(
  ({ classes, label, icon, active = false, ...props }) => {
    return (
      <Button classes={{ root: classes.root }} variant="contained" {...props}>
        {icon}
        <Typography variant="subtitle2">{label}</Typography>
      </Button>
    )
  }
)

export const PaymentStepComponent = withStyles(paymentStepStyles)(({ classes, model }) => {
  const { formatMessage, formatNumber } = useIntl()
  const { downloadPaymentFile, uploadPaymentFile } = usePaymentStep()

  // const [option, setOption] = useState(model['presupuesto_exterior']['d_comprobante'] ? 2 : 1)
  const [option, setOption] = useState(2)

  const canUploadFile = model['estado'] === APPLICATION_STATES.BUDGET_ACCEPTED.KEY
  const freeL = model['presupuesto_exterior']['distancia_asumida_nedgia'] || '-'
  const priceL = model['presupuesto_exterior']['precio_metro_tuberia']
    ? formatNumber(model['presupuesto_exterior']['precio_metro_tuberia'], {
        style: 'currency',
        currency: 'EUR',
      })
    : '-'
  const paymentNeeded =
    model['presupuesto_exterior']['distancia_real'] >
    model['presupuesto_exterior']['distancia_asumida_nedgia']
  const getPaymentState = () => {
    if (!paymentNeeded) return 'free'
    else if (APPLICATION_STATES.BUDGET_ACCEPTED.KEY === model.estado) return 'missing'
    else if (
      APPLICATION_STATES.PAYMENT_VALIDATION.KEY === model.estado ||
      APPLICATION_STATES.ORDER_PENDING.KEY === model.estado
    )
      return 'pending'
    else return 'accepted'
  }

  const handleUploadFile = (data) => uploadPaymentFile(model, data)

  const handleDownloadFile = (fileId) => downloadPaymentFile(fileId)

  const handleUploadError = (data) => feedback('error', data.mssg)

  return (
    <Box p={2} pt={1}>
      <Box display="flex" mb={1} flexWrap="wrap">
        <Box display="flex" alignItems="center" flexGrow={2}>
          <Hidden xsDown>
            <PaymentIcon />
          </Hidden>
          <Typography variant="h5">
            {formatMessage({
              id: `pages.gasApplications.wizard.payment.title${paymentNeeded ? 1 : 2}`,
            })}
          </Typography>
        </Box>
        <Box display="flex" flexGrow={1} alignItems="center" justifyContent="flex-end">
          <Typography variant="body2" className={classes.marginRight}>
            {formatMessage({ id: 'pages.gasApplications.wizard.payment.extra.label' })}:
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formatMessage({
              id: `pages.gasApplications.wizard.payment.extra.state.${getPaymentState()}`,
            })}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2" paragraph>
        {formatMessage({ id: 'pages.gasApplications.wizard.steps.1.tooltip' })}
      </Typography>
      <Divider light />
      <Box mt={2}>
        {paymentNeeded ? (
          false ? (
            <Typography variant="body1" gutterBottom>
              {formatMessage({ id: 'pages.gasApplications.wizard.payment.message5' })}
            </Typography>
          ) : (
            <>
              {/* <Box display="flex" flexWrap="wrap" mt={2} mb={2}>
                <Box mr={2}>
                  <OptionButton
                    icon={<CreditCardIcon className={classes.optionIcon} />}
                    label={formatMessage({
                      id: 'pages.gasApplications.wizard.payment.option1.label',
                    })}
                    active={option === 1}
                    disabled={PAYMENT_STEP_COMPLETED_STATES[model.estado]}
                    onClick={() => setOption(1)}
                  />
                </Box>
                <OptionButton
                  icon={<TransferIcon className={classes.optionIcon} />}
                  label={formatMessage({
                    id: 'pages.gasApplications.wizard.payment.option2.label',
                  })}
                  active={option === 2}
                  disabled={PAYMENT_STEP_COMPLETED_STATES[model.estado]}
                  onClick={() => setOption(2)}
                />
              </Box>
              <Divider light /> */}
              <Box mt={2}>
                <Typography variant="body1" gutterBottom>
                  {option === 1
                    ? !PAYMENT_NEEDED_STATES[model.estado]
                      ? formatMessage({ id: 'pages.gasApplications.wizard.payment.message5' })
                      : formatMessage({ id: 'pages.gasApplications.wizard.payment.message4' })
                    : formatMessage(
                        { id: 'pages.gasApplications.wizard.payment.message1' },
                        { iban: model['presupuesto_exterior']['iban'] }
                      )}
                </Typography>
                {option !== 1 ? (
                  <Typography variant="body1">
                    {formatMessage({ id: 'pages.gasApplications.wizard.payment.message2' })}
                  </Typography>
                ) : null}
              </Box>
              {option === 1 ? (
                !PAYMENT_NEEDED_STATES[model.estado] ? null : (
                  <Box textAlign="right">
                    <PaymentProcessor {...{ model }} />
                  </Box>
                )
              ) : (
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {model['presupuesto_exterior']['d_comprobante'] && (
                    <Box flexGrow={1}>
                      <Typography variant="subtitle2">
                        {model['presupuesto_exterior']['d_comprobante']['nombre']}
                      </Typography>
                      <span
                        onClick={() =>
                          handleDownloadFile(model['presupuesto_exterior']['d_comprobante']['id'])
                        }
                      >
                        <Typography variant="body2" className={classes.downloadLink}>
                          {formatMessage({
                            id: 'pages.gasApplications.wizard.payment.button3.label',
                          })}
                        </Typography>
                      </span>
                    </Box>
                  )}
                  {canUploadFile ? (
                    <Box textAlign="right" flexGrow={1}>
                      <FileUploader
                        maxSize={PAYMENT_FILE_SIZE}
                        applicationId={model['id']}
                        budgetId={model['presupuesto_exterior']['id']}
                        label={formatMessage({
                          id: 'pages.gasApplications.wizard.payment.button2.label',
                        })}
                        icon={<PublishOutlinedIcon />}
                        extra={formatMessage(
                          {
                            id: 'pages.gasApplications.wizard.payment.uploadMessage',
                          },
                          { size: PAYMENT_FILE_SIZE / 1000000 }
                        )}
                        color="primary"
                        type="comprobante_pago"
                        accept={['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']}
                        onSuccess={handleUploadFile}
                        onError={(data) => handleUploadError(data)}
                      />
                    </Box>
                  ) : null}
                </Box>
              )}
            </>
          )
        ) : (
          <Typography variant="body1">
            {formatMessage(
              { id: 'pages.gasApplications.wizard.payment.message3' },
              { freeL, priceL }
            )}
          </Typography>
        )}
        <Box display="flex" justifyContent="flex-end">
          <CancelBtn {...{ model }} />
        </Box>
      </Box>
    </Box>
  )
})
