import React from 'react'
import {
  withStyles,
  TextField,
  FormControl,
  FormHelperText,
  Tooltip,
  Icon,
  Typography,
} from '@material-ui/core'

import { textInputStyles } from './text-input.styles'

const ROWS_MAX = 5

export const TextInputReplace = React.memo(
  withStyles(textInputStyles)(({ classes, type = 'text', ...props }) => {
    return (
      <FormControl className={classes.root}>
        {props.title ? (
          <div className={classes.title}>
            {props.title}
            {props.tooltip ? (
              <span style={{ fontSize: 16, marginLeft: 4 }}>
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                      {props.tooltip}
                    </Typography>
                  }
                >
                  <Icon fontSize="inherit">help_outline</Icon>
                </Tooltip>
              </span>
            ) : null}
          </div>
        ) : null}
        {type === 'text' ? (
          <Typography color="textSecondary">
            {props.value ? props.value + (props.units ? ' ' + props.units : '') : '--'}
          </Typography>
        ) : (
          <TextField
            rowsMax={ROWS_MAX}
            {...props}
            type={type}
            variant="standard"
            value={props.value || ''}
            placeholder="--"
            InputProps={{ className: classes.input, ...props.InputProps }}
            // inputProps={{ ...props.InputProps }}
          />
        )}
      </FormControl>
    )
  })
)
