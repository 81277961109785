export const encodeToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let result = ''
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = (e) => {
      // result = e.target.result.split(',')[1]
      result = e.target.result
      resolve(result)
    }
    reader.onerror = (e) => {
      reject(e)
    }
  })
}

export const isAcceptedFormat = (filename, acceptedFormats) => {
  let acceptedExtensions = acceptedFormats.map((item) => item.split('/')[1])
  let filenameAndExtension = filename.toLowerCase().split('.')
  // without filename or extension
  if (filenameAndExtension.length == 1) {
    return false
  }
  let extension = filenameAndExtension[filenameAndExtension.length - 1]
  return acceptedExtensions.indexOf(extension) > -1
}
