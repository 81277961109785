export const modalStyles = (theme) => ({
  halfView: {
    margin: theme.spacing(0),
    marginTop: theme.spacing(20),
  },
  closeButton: {
    position: 'absolute',
    right: '0',
  },
})
