import { APPLICATION_STATES } from './application-states.constants'

export const MIN_MILISECONDS_REFRESH_GAS_APPS = 2000

export const FILTERED_STATES = {
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: APPLICATION_STATES.NOT_ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.ASSIGNED.KEY]: APPLICATION_STATES.ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: APPLICATION_STATES.BUDGET_CREATED.DESCRIPTION,
}

export const PROCESSING_STATES = {
  [APPLICATION_STATES.CREATED.KEY]: APPLICATION_STATES.CREATED.DESCRIPTION,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: APPLICATION_STATES.NOT_ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.ASSIGNED.KEY]: APPLICATION_STATES.ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: APPLICATION_STATES.BUDGET_CREATED.DESCRIPTION,
}