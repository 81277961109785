import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Badge, IconButton, Tooltip, withStyles } from '@material-ui/core'
// import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import { useWidth } from '../../../../core/utils'
import LogoNedgiaFull from '../../../../resources/images/logo_nedgia_full.svg'
import LogoNedgia from '../../../../resources/images/logo_nedgia.svg'
import { MenuComponent as Menu } from '../../../menu'
import { NotificationDrawer } from '../../../../features/notifications'
import { DEFAULT_ROUTE, MENU_ITEMS, PROFILE_ROUTE } from '../constants'
import { privateLayoutStyles } from './private-layout.styles'
import { NotificationsContext } from '../../../../features/notifications/contexts'
import { APP_VERSION_MSSG } from '../../public-layout/constants'

export const PrivateLayoutComponent = withStyles(privateLayoutStyles)(({ children, classes }) => {
  let screenWidth = useWidth()
  const { notificationsData, toggleNotificationsShow } = useContext(NotificationsContext)
  const isMobileWidth = screenWidth === 'sm' || screenWidth === 'xs'

  const mobileView = MENU_ITEMS.length > 5
  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <div className={classes.headerLeftContent} style={mobileView ? { order: '2' } : {}}>
          <div className={classes.logo}>
            <Tooltip title={APP_VERSION_MSSG}>
              <Link to={DEFAULT_ROUTE}>
                {screenWidth === 'xs' ? (
                  <img src={LogoNedgia} />
                ) : (
                  <img
                    src={LogoNedgiaFull}
                    alt="Nedgia_Logo"
                    style={{ width: '100%', height: 'auto' }}
                  />
                )}
              </Link>
            </Tooltip>
          </div>
        </div>
        <div className={classes.menu} style={mobileView ? { order: '1' } : {}}>
          <Menu items={MENU_ITEMS} isMobileWidth={isMobileWidth} mobileView={mobileView} />
        </div>
        <div className={classes.headerRightContent} style={mobileView ? { order: '3' } : {}}>
          {/* <Link to={SETTINGS_ROUTE}>
            <IconButton className={classes.iconButton}>
              <SettingsOutlinedIcon />
            </IconButton>
          </Link>
           */}
          <IconButton className={classes.iconButton} onClick={() => toggleNotificationsShow()}>
            <Badge
              variant="dot"
              color="error"
              invisible={notificationsData.filter((el) => !el.visto).length === 0}
            >
              <NotificationsOutlinedIcon />
            </Badge>
          </IconButton>
          <Link to={PROFILE_ROUTE}>
            <IconButton className={classes.iconButton}>
              <AccountCircleOutlinedIcon />
            </IconButton>
          </Link>
        </div>
      </div>
      <div className={classes.mainArea}>
        <div className={classes.bodyWrapper}>{children}</div>
        <NotificationDrawer />
      </div>
    </div>
  )
})
