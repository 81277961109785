import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../../ui'
import { APPLICATION_STATES } from '../../../constants'
import { usePaymentStep } from './payment-step.hook'

const PAYMENT_URL = 'https://sis-t.redsys.es:25443/sis/realizarPago'

const INITIAL_PAYMENT_DATA = {
  Ds_SignatureVersion: '',
  Ds_MerchantParameters: '',
  Ds_Signature: '',
}

export const PaymentProcessor = ({ model }) => {
  const { formatMessage } = useIntl()
  const { getPaymentInfo } = usePaymentStep()
  const paymentBtnId = `paymentBtn${model.id}`
  const paymentPending = model['estado'] === APPLICATION_STATES.BUDGET_ACCEPTED.KEY

  const [loading, setLoading] = useState(false)
  const [paymentData, setPaymentData] = useState(INITIAL_PAYMENT_DATA)

  const handlePaymentProcessing = () => {
    setLoading(true)
    getPaymentInfo(model.id).then(({ data }) => {
      setPaymentData(data)
    })
  }

  useEffect(() => {
    if (paymentData.Ds_MerchantParameters) document.getElementById(paymentBtnId).click()
  }, [paymentData])

  return (
    <>
      <ThemeButton
        color="primary"
        labelM="large"
        disabled={!paymentPending}
        loading={loading}
        onClick={handlePaymentProcessing}
      >
        {formatMessage({ id: 'pages.gasApplications.wizard.payment.button1.label' })}
      </ThemeButton>
      <form method="post" name="from" action={PAYMENT_URL}>
        <input
          type="hidden"
          name="Ds_MerchantParameters"
          value={paymentData.Ds_MerchantParameters}
        />
        <input type="hidden" name="Ds_Signature" value={paymentData.Ds_Signature} />
        <input type="hidden" name="Ds_SignatureVersion" value={paymentData.Ds_SignatureVersion} />
        <button id={paymentBtnId} type="submit" style={{ display: 'none' }} />
      </form>
    </>
  )
}
