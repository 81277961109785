import { useCallback } from 'react'
import { useAjaxContext } from '../../../core/ajax/contexts'

export const useNotificationCalls = () => {
  const { getById, putById } = useAjaxContext()

  const getNotifications = useCallback(
    (userId) =>
      new Promise((resolve) => {
        getById('/notificaciones', userId)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            console.error(response && response.data)
          })
      }),
    []
  )

  const seenNotifications = useCallback(
    (notifId) =>
      new Promise((resolve) => {
        putById('/notificaciones/seen', notifId)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            console.error(response && response.data)
          })
      }),
    []
  )

  return {
    getNotifications: getNotifications,
    seenNotifications: seenNotifications,
  }
}
