import { createSlice } from '@reduxjs/toolkit'
import { APPLICATION_STATES } from '../../../features/gas-applications/constants/index'

const GAS_APPS_INITIAL_STATE = {
  isLoading: true,
  msLoaded: false,
  pendingApplications: [],
}

const gasApplicationsSlice = createSlice({
  name: 'gasApplications',
  initialState: GAS_APPS_INITIAL_STATE,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setApplications: (state, action) => {
      state.applications = action.payload
      state.msLoaded = Date.now()
    },
    setPendingCancelApps: (state, action) => {
      console.log(action.payload)
      action.payload.map(
        (item) =>
          item['estado'] === APPLICATION_STATES.CLIENT_CANCEL_PENDING.KEY &&
          !state.pendingApplications.some((pendingApp) => {
            return pendingApp.id === item.id
          }) &&
          state.pendingApplications.push(item)
      )
    },
    setApplication: (state, { payload }) => {
      const index = state.applications.findIndex((item) => item['id'] === payload.id)
      state.applications[index] = { ...payload, individual_update: true }
    },
    resetApplications: () => GAS_APPS_INITIAL_STATE,
    setFailure: (state) => {
      state.msLoaded = true
    },
  },
})

export const gasApplicationsReducer = gasApplicationsSlice.reducer

export const selectApplications = (state) => state.gasApplications.applications

export const {
  setPendingCancelApps,
  setApplications,
  setApplication,
  setLoading,
  setFailure,
  resetApplications,
} = gasApplicationsSlice.actions

export const nextApplicationState = ({ model, nextStep, setStep }) => (dispatch) => {
  console.log('nextApplicationState')
  dispatch(setApplication(model))
  setStep && setStep(nextStep)
}

export const setApplicationsData = (data) => (dispatch) => {
  dispatch(setLoading(true))
  dispatch(setApplications(data))
  dispatch(setPendingCancelApps(data))
  dispatch(setLoading(false))
}
