import React from 'react'
import { useIntl } from 'react-intl'

import { BasicList } from '../../../../../ui'
import { BULLETIN_LIST_HEADER } from './constants'

export const BulletinEventsList = ({ model }) => {
  const { formatMessage } = useIntl()

  let bulletimItems = [
    {
      dg_ts_insert:
        model?.presupuesto_exterior?.fecha_pendiente_boletin || model['fecha_solicitud'],
      mensaje: formatMessage({ id: 'pages.gasApplications.wizard.bulletin.event.1' }),
    },
  ]
  if (model['certificado_IRG3']['fecha_documento_IRG3'])
    bulletimItems.unshift({
      dg_ts_insert: model['certificado_IRG3']['fecha_documento_IRG3'],
      mensaje: formatMessage({ id: 'pages.gasApplications.wizard.bulletin.event.2' }),
    })

  return <BasicList config={BULLETIN_LIST_HEADER} data={bulletimItems} />
}
