import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Hidden } from '@material-ui/core'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'

import { feedback } from '../../../../../core/feedback'
import { ExpansionPanel, FileUploader, InfoBox } from '../../../../../ui'
import { ReactComponent as BulletinIcon } from '../../../../../resources/images/icon_bulletin.svg'
import { APPLICATION_FULL_PATHS } from '../../../constants'
import {
  BULLETIN_STEP_COMPLETED_STATES,
  BULLETIN_STEP_UPLOAD_PERMITTED_STATES,
  BULLETIN_STEP_UNCANCELLABLE_STATES,
  MUST_ANSWER_CANCEL_REQUEST,
} from './constants'
import { BULLETIN_FILE_SIZE, INST_CONFIG_URL } from './constants'

import { useBulletinStep } from './bulletin-step.hook'
import { BulletinEventsList } from './bulletin-events-list.component'
import { CancelBtn } from '../../buttons'
import { bulletinStepStyles } from './bulletin-step.styles'

export const BulletinStepComponent = withStyles(bulletinStepStyles)(({ classes, model }) => {
  const { formatMessage } = useIntl()
  const { downloadBulletinFile, uploadBulletinFile } = useBulletinStep()

  const isIndoorInstallation = !APPLICATION_FULL_PATHS[model['camino_solicitud']]
  const isCancellationPermitted = !BULLETIN_STEP_UNCANCELLABLE_STATES[model['estado']]
  const budget = APPLICATION_FULL_PATHS[model['camino_solicitud']]
    ? model['presupuesto_exterior']
    : model['presupuesto_interior']
  const isUploadPermitted = BULLETIN_STEP_UPLOAD_PERMITTED_STATES[model['estado']]
  const mustAnswerCancelRequest = MUST_ANSWER_CANCEL_REQUEST[model['estado']]
  /* const isRiteUploadPermitted = !BULLETIN_STEP_UPLOAD_RITE_NOT_PERMITTED_STATES[model['estado']]
   const showRiteUploadButton = model['calefaccion'] && isRiteUploadPermitted
   const showRiteMessage = model['calefaccion'] && !model['d_CC'] && isRiteUploadPermitted*/
  const paymentNeeded =
    APPLICATION_FULL_PATHS[model['camino_solicitud']] &&
    budget['distancia_real'] > budget['distancia_asumida_nedgia']
  const alreadyCompleted = BULLETIN_STEP_COMPLETED_STATES[model['estado']]
  // const getBulletinState = () => {
  //   if (alreadyCompleted) return 'validated'
  //   else if (model['estado'] === APPLICATION_STATES.CERTIFICATE_REJECTED.KEY) return 'rejected'
  //   else if (model['estado'] === APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY) return 'uploaded'
  //   else return 'pending'
  // }
  const handleUploadFile = (data, documentIdentification) => {
    uploadBulletinFile(model, data, documentIdentification)
      .then(() => {
        handleSuccessfulOperation('Documento subido')
      })
      .catch(() => {
        handleErrors('Error al subir el documento')
      })
  }

  const handleDownloadFile = (fileId) => downloadBulletinFile(fileId)
  const handleUploadError = (data) => feedback('error', data.mssg)
  const handleSuccessfulOperation = (message) => feedback('success', message)
  const handleErrors = (message) => feedback('error', message)
  const handleGoInstallationConfig = () => window.open(INST_CONFIG_URL(model))

  return (
    <ExpansionPanel
      isExpanded={!alreadyCompleted}
      triggerPosition="right"
      summary={
        <Box mb={1}>
          <Box display="flex" alignItems="center" mb={1}>
            <Hidden xsDown>
              <BulletinIcon />
            </Hidden>
            <Typography variant="h5">
              {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.title' })}
            </Typography>
          </Box>
          <Typography variant="body2" paragraph>
            {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.1' })}
            {model['mercado'] !== 'SV' && model['camino_solicitud'] === '01'
              ? paymentNeeded
                ? formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.description.1.1',
                  })
                : formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.description.1.2',
                  })
              : ''}
          </Typography>
          {/* {model['mercado'] !== 'SV' && (
            <Box>
              <Typography variant="body2">
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.3' })}
              </Typography>
              <Typography variant="body2" paragraph>
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.4.1' })}
                <span onClick={handleGoInstallationConfig} className={classes.urlLink}>
                  {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.4.2' })}
                </span>
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.description.4.3' })}
              </Typography>
            </Box>
          )} */}
          <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <Box display="flex">
              <Typography variant="body2" className={classes.marginRight}>
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.doc.state.label' })}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {/* {formatMessage({
                  id: `pages.gasApplications.wizard.bulletin.doc.state.${getBulletinState()}`,
                })} */}
                {model['certificado_IRG3']['estado'] ||
                  formatMessage({
                    id: `pages.gasApplications.wizard.bulletin.doc.state.pending`,
                  })}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body2" className={classes.marginRight}>
                {formatMessage({ id: 'pages.gasApplications.wizard.bulletin.cups.label' })}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {model['cups'] || '--'}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body2" className={classes.marginRight}>
                {formatMessage({
                  id: 'pages.gasApplications.wizard.bulletin.cups.state.label',
                })}
                :
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {model['estado_cups'] || '--'}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
      details={<BulletinEventsList model={model} />}
      //TODO: Make a component for download link + title + name of the document (when the flow becomes clear)
      footer={
        <>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            {model['certificado_IRG3']['d_IRG3'] && (
              <Box flexGrow={1}>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.certificate.label',
                  })}
                  :
                </Typography>
                <Typography variant="subtitle2">
                  {model['certificado_IRG3']['d_IRG3']['nombre']}
                </Typography>
                <span onClick={() => handleDownloadFile(model['certificado_IRG3']['d_IRG3']['id'])}>
                  <Typography variant="body2" className={classes.downloadLink}>
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.download.label',
                    })}
                  </Typography>
                </span>
              </Box>
            )}
            {isIndoorInstallation && model['d_RC'] ? (
              <Box flexGrow={1}>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.summary.label',
                  })}
                  :
                </Typography>
                <Typography variant="subtitle2">{model['d_RC']['nombre']}</Typography>
                <span onClick={() => handleDownloadFile(model['d_RC']['id'])}>
                  <Typography variant="body2" className={classes.downloadLink}>
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.download.label',
                    })}
                  </Typography>
                </span>
              </Box>
            ) : null}
            {isIndoorInstallation && model['d_CCEE'] ? (
              <Box flexGrow={1}>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.ccee.label',
                  })}
                  :
                </Typography>
                <Typography variant="subtitle2">{model['d_CCEE']['nombre']}</Typography>
                <span onClick={() => handleDownloadFile(model['d_CCEE']['id'])}>
                  <Typography variant="body2" className={classes.downloadLink}>
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.download.label',
                    })}
                  </Typography>
                </span>
              </Box>
            ) : null}
            {model['d_CC'] && (
              <Box flexGrow={1}>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.bulletin.rite.label',
                  })}
                  :
                </Typography>
                <Typography variant="subtitle2">{model['d_CC']['nombre']}</Typography>
                <span onClick={() => handleDownloadFile(model['d_CC']['id'])}>
                  <Typography variant="body2" className={classes.downloadLink}>
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.download.label',
                    })}
                  </Typography>
                </span>
              </Box>
            )}
            <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center">
              {isUploadPermitted && (
                <Box ml={3}>
                  <FileUploader
                    maxSize={BULLETIN_FILE_SIZE}
                    applicationId={model['id']}
                    label={formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.button2.label',
                    })}
                    icon={<PublishOutlinedIcon />}
                    extra={formatMessage(
                      {
                        id: 'pages.gasApplications.wizard.bulletin.uploadMessage',
                      },
                      { size: BULLETIN_FILE_SIZE / 1000000 }
                    )}
                    color="primary"
                    type="IR"
                    accept={['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']}
                    onSuccess={(data) => handleUploadFile(data, 'd_IRG3_id')}
                    onError={(data) => handleUploadError(data)}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  />
                </Box>
              )}
              {/*{showRiteUploadButton && (
                <Box ml={3}>
                  <FileUploader
                    maxSize={BULLETIN_FILE_SIZE}
                    applicationId={model['id']}
                    label={formatMessage({
                      id: 'pages.gasApplications.wizard.bulletin.button.rite.label',
                    })}
                    icon={<PublishOutlinedIcon />}
                    extra={formatMessage(
                      {
                        id: 'pages.gasApplications.wizard.bulletin.rite.uploadMessage',
                      },
                      { size: BULLETIN_FILE_SIZE / 1000000 }
                    )}
                    color="primary"
                    type="CC"
                    accept={['application/pdf']}
                    onSuccess={(data) => handleUploadFile(data, 'd_CC_id')}
                    onError={(data) => handleUploadError(data)}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  />
                </Box>
              )}*/}
              {isCancellationPermitted && !mustAnswerCancelRequest ? (
                <Box ml={1}>
                  <CancelBtn {...{ model }} />
                </Box>
              ) : null}
            </Box>
            {/*{showRiteMessage && (
              <InfoBox
                severity="warning"
                message={formatMessage({
                  id: 'pages.gasApplications.wizard.bulletin.rite.message',
                })}
              />
            )}*/}
          </Box>
        </>
      }
    />
  )
})
