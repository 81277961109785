import React, { useMemo, useState, useEffect } from 'react'
import { makeStyles, withStyles, Stepper, Step, StepLabel, StepConnector } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

import { useAlarm } from '../../../../core/utils/useAlarm'
import { APPLICATION_STATES } from '../../constants'
import { getCurrentWizardStep, getActiveWizardSteps, isProcessFinished } from './wizard.logics'
import { wizardStyles, stepStyles, stepIconStyles, stepConnectorStyles } from './wizard.styles'

export const WizardComponent = withStyles(wizardStyles)(({ classes, steps, model }) => {
  const [step, setStep] = useState(getCurrentWizardStep(model))
  const { postAlarm } = useAlarm()
  const [alarm, setAlarm] = useState(false)

  useEffect(() => {
    if (alarm) {
      postAlarm('unkown_petition_state', 'state:' + alarm + ' , petition id:' + model['id'])
    }
  }, [alarm])

  useEffect(() => {
    const nextStep = getCurrentWizardStep(model)
    if (step !== nextStep) setStep(nextStep)
  }, [model['estado']])

  const StepViewFiltered = steps.find(({ id }) => id === step)
  if (!StepViewFiltered) {
    if (!alarm) {
      setAlarm(model['estado'])
    }
    if (model.estado === APPLICATION_STATES.VALIDATION_PENDING.KEY) {
      return 'Pendiente validar gestor'
    } else {
      return 'Petición en estado desconocido'
    }
  }
  const StepView = StepViewFiltered.component

  return useMemo(
    () =>
      StepView && (
        <div className={classes.wrapper}>
          <Stepper
            alternativeLabel
            activeStep={step}
            connector={<StepConnectorComponent />}
            className={classes.stepper}
          >
            {steps.map((item, i) => (
              <Step key={i} completed={item.id < step}>
                <StepComponent {...{ ...item, step, setStep, model }} />
              </Step>
            ))}
          </Stepper>
          <div className={classes.view}>
            <StepView {...{ step, setStep, model }} />
          </div>
        </div>
      ),
    [StepView, step, steps.length]
  )
})

const StepComponent = withStyles(stepStyles)(({ classes, ...props }) => {
  const active = getActiveWizardSteps(props.model).some((stepId) => stepId === props.id)
  const completed = isProcessFinished(props.model) || props.id < getCurrentWizardStep(props.model)

  const tooltipClasses = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      width: 120,
    },
  }))

  return (
    <Tooltip
      arrow
      placement="top"
      classes={tooltipClasses()}
      title={props.tooltip}
      disableHoverListener={active || completed}
    >
      <StepLabel
        alternativeLabel
        className={active || completed ? classes.navegable : ''}
        StepIconComponent={() => <StepIconComponent active={active} completed={completed} />}
        onClick={() => (completed || active) && props.setStep(props.id)}
      >
        <div className={classes.label}>{props.label}</div>
      </StepLabel>
    </Tooltip>
  )
})

const StepIconComponent = withStyles(stepIconStyles)(({ classes, active, completed }) => {
  return (
    <div className={classes.root}>
      {active ? (
        <div className={classes.active} />
      ) : completed ? (
        <CheckIcon className={classes.completed} />
      ) : (
        <div className={classes.disabled} />
      )}
    </div>
  )
})

const StepConnectorComponent = withStyles(stepConnectorStyles)(StepConnector)
